/* global H5P */
/* global PBxAPI */

/**
 * @param {H5P.XAPIEvent} event
 */
function sendXApiStatement(event) {
	const verb = event.getVerb();

	if (! ['completed', 'answered'].includes(verb)) {
		return;
	}

	// this is for compound activities, we don't send child answered questions
	if ( event.getVerifiedStatementValue(['context', 'contextActivities', 'parent'])) {
		return;
	}

	const data = new FormData();

	if (window.top !== window.self) {
		data.append('lms_context', true);
	}

	data.append('post_id', PBxAPI.post_id);
	data.append('h5p_id', event.getVerifiedStatementValue(['object', 'definition', 'extensions', 'http://h5p.org/x-api/h5p-local-content-id']));
	data.append('score', event.getScore());
	data.append('max_score', event.getMaxScore());
	data.append('statement', JSON.stringify(event.data.statement));

	console.log('data', data)
	console.log('statement', event.data.statement);

	fetch( PBxAPI.url, {
		method: 'POST',
		body: data,
	} )
		.then( response => response.json() )
		.catch( error => console.error( 'error:', error ) );
}

document.addEventListener( 'DOMContentLoaded', function () {
	if ( typeof H5P === 'object' ) {
		console.log('is an object');
		H5P.externalDispatcher.on( 'xAPI', sendXApiStatement);
	}
} );
